<div class="row product-info-container">
  <div class="col-5 container-img-prod">
    <img [class]="checkout ? 'img-prod' : 'img-prod-check'" [src]="product.imagen" alt=""/>
    <div class="variable-weight-tag txt-stl-primary" *ngIf="product.pesoVariable">
      Peso variable
    </div>
  </div>

  <div class="col-7 container-name-price">
    <div class="info-product">
      <div class="title-product txt-stl-primary">
        {{ product.nombre }}
      </div>
      <div class="weight-product txt-stl-primary" *ngIf="product.pesoVariable">
        {{ product.pesoGramosVariable }}g - $ {{ (product.precio / product.pesoGramos).toFixed(2) | number }}/g
      </div>
      <div class="weight-product txt-stl-primary" *ngIf="!product.pesoVariable">
        {{ product.pesoGramos }}g - $ {{ (product.precio / product.pesoGramos).toFixed(2) | number }}/g
      </div>
      <div class="price-product txt-stl-primary" *ngIf="product.pesoVariable">
        {{
          (product.precio / product.pesoGramos) *
            product.pesoGramosVariable *
            product.cantidad | customCurrency
        }}
      </div>
      <div class="price-product txt-stl-primary" *ngIf="!product.pesoVariable">
        {{ product.precio * product.cantidad | customCurrency }}
      </div>
      <div class="unidad" *ngIf="product.pesoVariable">{{product.cantidad}} Und. aprox.</div>
      <div class="unidad" *ngIf="!product.pesoVariable">{{product.cantidad}} Und.</div>
</div>
