import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalFunctionsService {
  constructor(
    private http: HttpClient
  ) {}

  /**
   * Normalized a text
   * @param text
   */
  normalizedText(text: string): string {
    let textTrans = text.toLowerCase();
    textTrans = textTrans.replace(/á/gi, 'a');
    textTrans = textTrans.replace(/é/gi, 'e');
    textTrans = textTrans.replace(/í/gi, 'i');
    textTrans = textTrans.replace(/ó/gi, 'o');
    textTrans = textTrans.replace(/ú/gi, 'u');
    textTrans = textTrans.replace(/ñ/gi, 'n');
    return textTrans;
  }

  sendForm(endPoint, form){
    return this.http.post(`${environment.backendUrl}email/${endPoint}`, form);
  }
}
