import {
  Component,
  OnInit,
  Input,
  PLATFORM_ID,
  Inject,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { isPlatformBrowser } from '@angular/common';
import { Category, Product } from 'src/app/interfaces/products.interface';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { BaseHeaderComponent } from '../base-header/base-header.component';
import { AuthService } from '../../core/services/auth/auth.service';
import { ServiceRecetasService } from 'src/app/core/services/recetas/service-recetas.service';
import { SalePointsService } from 'src/app/core/services/sale-points/sale-points.service';
import { Address, SalePoint, ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/user.interface';
import * as _ from 'lodash';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseHeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() public categoriesHeader: Category[];
  @Input() public page: string;
  /**Products container which will be header */
  firstCategories: Category[] = [];
  /**Products container which will be in Otros  */
  secondCategories: Category[] = [];

  /**Header tabs */
  public tiendaTab: boolean = false;
  public recetasTab: boolean = false;
  public conocenosTab: boolean = false;

  public cat = [];
  // Lista de recetas global
  public recetasGlobales: any[] = [];
  public recipeCategories = [
    {
      nombre: 'Todas',
      url: '../../../../assets/imgs/imgs-tests/todas-recetas.png',
    },
  ];

  conocenosContent = [
    {
      nombre: '¿Quienes somos?',
      image: '../../../assets/imgs/img-about/conoce_somos.png',
      slug: 'quienes-somos'
    },
    {
      nombre: 'Contáctanos',
      image: '../../../assets/imgs/img-about/conoce_contact.png',
      slug: 'contactanos'
    },
    {
      nombre: 'Trabaja con nosotros',
      image: '../../../assets/imgs/img-about/conoce_work.png',
      slug: 'trabaja-con-nosotros'
    }
  ]

  public selectedCategory = '';
  public salePoints: SalePoint[];
  public categories: Category[] = [];
  public productsToShow: Product[] = [];
  public subscribeShippingMethodGlobal: Subscription;

  public user: User = {
    email: '',
    celular: '',
    createdAt: NaN,
    nombre: '',
    uid: '',
    tipo: '',
    aceptoTerminos: false,
    direccionIp: '',
  };

  constructor(
    protected router: Router,
    protected userService: UserService,
    protected authService: AuthService,
    protected activatedRoute: ActivatedRoute,
    protected productService: ProductsService,
    protected productsService: ProductsService,
    protected salePointsService: SalePointsService,
    protected shoppingCartService: ShoppingCartService,
    protected serviceRecetasService: ServiceRecetasService,
    protected localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) protected platFormId
  ) {
    super(router, userService, productService, shoppingCartService, authService, localStorage ,platFormId);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getRecipes();
    this.getSalePointOperation().then(() => {
      if (isPlatformBrowser(this.platFormId)) {
        this.initializeUserAndProductInfo();
        this.observableAddress();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewChecked(): void {
    if (this.categoriesHeader) {
      if (this.categoriesHeader.length > 0 && this.firstCategories.length == 0) {
        for (let i = 0; i < this.categoriesHeader.length; i++) {
          if (i < 1) {
            this.firstCategories.push(this.categoriesHeader[i]);
          } else {
            this.secondCategories.push(this.categoriesHeader[i]);
          }
        }
      }
    }
  }

  observableAddress(): void {
    this.subscribeShippingMethodGlobal = this.userService.sendGlobalShippingMethodObservable.subscribe(
      (shippingMethod) => {
        this.getCategoriesWithProductsAPI(shippingMethod, true);
      }
    );
  }

  getSalePointOperation(): Promise<any> {
    return this.salePointsService.getAllSalePoints().toPromise().then((res:any) => {
      this.salePoints = res.map((doc) => {
        let data = doc as SalePoint;
        return data;
      }).filter(sp => sp.bodega && sp.zonaCobertura && sp.lat && sp.lng && sp.tpv);      
    });
  }

  initializeUserAndProductInfo(): void {
    if (isPlatformBrowser(this.platFormId)) {
      const userLogged = JSON.parse(localStorage.getItem('userLogged'));
      if (userLogged) {
        this.userService.getUserIdLogged().subscribe((userId) => {
          if (userId) {
            this.getUserInfo(userId);
          }
        });
      } else {
        const shippingMethod = JSON.parse(localStorage.getItem('shippingMethod'));
        this.getCategoriesWithProductsAPI(shippingMethod, false);
        this.getShippingMethodActivated(shippingMethod);
      }
    }
  }

  /**
   * Get user information
   * @param userId User id
   */
  getUserInfo(userId: string): void {
    this.userService.getUserbyId(userId).subscribe((doc) => {
      this.user = doc as User;
      let address: Address;
      let shippingMethod: ShippingMethod;
      // Send a address if there is valid and coveraged address
      if (address  && this.user.tipoEntrega !== 'Recogida') {
        address = this.user.direcciones.filter(
          (address) => address.principal === true
        )[0];
        shippingMethod = {
          type: ShippingMethodType.SHIPPING,
          address,
        };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      } else {
        shippingMethod = { type: ShippingMethodType.SHIPPING };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      }
      if (this.user.tipoEntrega && this.user.tipoEntrega === 'Recogida') {        
        shippingMethod = {
          type: ShippingMethodType.IN_STORE,
          salePoint: this.salePoints.filter((sp) => sp.id === this.user.puntoDeVentaId)[0],
        };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      }
      this.getShippingMethodActivated(shippingMethod);
    });
  }

  getShippingMethodActivated(shippingMethod: ShippingMethod): void {
    if (shippingMethod?.type === ShippingMethodType.SHIPPING) {
      if (shippingMethod.address) {
        this.userService.onSendGlobalShippingMethod(shippingMethod);
      } else {
        if (isPlatformBrowser(this.platFormId)) {
          shippingMethod = JSON.parse(localStorage.getItem('shippingMethod'));
          this.userService.onSendGlobalShippingMethod(shippingMethod);
        }
      }
    } else if (shippingMethod?.type === ShippingMethodType.IN_STORE) {
      this.userService.onSendGlobalShippingMethod(shippingMethod);
    }
  }

  async getCategoriesWithProductsAPI(shippingMethod: ShippingMethod, isObservable: boolean) {
    this.categories = [];
    const citySelected = JSON.parse(localStorage.getItem('citySelected'));

    let allProducts = this.productsService.allProductsResponse ?? await this.productsService.getAllProductsAPI();
    let categories  = this.productsService.allCategoriesResponse ?? await this.productsService.getAllCategoriesAPI();

    if (allProducts && categories) {
      this.productsService.getCategoriesAndProductsAPI(categories, allProducts).then((data) => {
        if (isObservable || citySelected) {
          const result = this.productsService.filterCategoriesByWarehouse(data, shippingMethod);
          this.categories = _.orderBy(result, ['index', 'nombre'], ['asc', 'asc']);
        } else {
          this.categories = _.orderBy(data, ['index', 'nombre'], ['asc', 'asc']);
        }
        this.categories = this.categories.filter(cat => cat.productos.length > 0);
        this.productsService.categoriesWithProductsResponse = this.categories;
      })
    }
  }
  collapseTab(tab: string) {
    if (tab === 'tienda') {
      this.tiendaTab = !this.tiendaTab;
    } else {
      this.tiendaTab = false;
      document.getElementById('navbarToggleStoreContent').classList.remove('show');
    }
    
    if (tab === 'recetas') {
      this.recetasTab = !this.recetasTab;
    } else {
      this.recetasTab = false;
      document.getElementById('navbarToggleRecipesContent').classList.remove('show');
    }
    
    if (tab === 'conocenos') {
      this.conocenosTab = !this.conocenosTab;
    } else {
      this.conocenosTab = false;
      document.getElementById('navbarToggleConocenosContent').classList.remove('show');
    }
  }

  redirectTo(route: string): void {
    this.router.navigate([`${route}`])
  }
  getRecipes() {
    this.serviceRecetasService.getRecipes('categorias_recetas').then((res:any) => {
      res.forEach((element) => {
        this.cat = element.categorias;
      });
      this.cat.sort((a, b) => a.nombre.localeCompare(b.nombre));
      this.cat.forEach((element) => {
        this.recipeCategories.push(element);
      });
    });
    const filters = [{ field: 'estado', operator: '==', value: 'Activa' }];
    this.serviceRecetasService.getRecipes('recetas', filters).then((res:any) => {
      res.forEach((element) => {
        this.recetasGlobales.push(element);
      });
    });
  }

  updateSelectedRecipe(selected) {
    this.serviceRecetasService.setRecipe(selected);
  }

  redirectRecipe(categorie): void {
    this.router.navigate(['/recetas']);
    this.updateSelectedRecipe(categorie);
    document.getElementById('navbarToggleRecipesContent').classList.remove('show');
    this.recetasTab = false;
  }

  redirectStore(slug): void {
    this.router.navigate(['/tienda/', slug]);
    document.getElementById('navbarToggleStoreContent').classList.remove('show');
    this.tiendaTab = false;
  }

}
