<button
  type="button"
  class="btn btn-add-card"
  data-toggle="modal"
  data-target="#addCardModal"
>
  + Agregar tarjeta de crédito
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="addCardModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mb-2">
          <div class="col">
            <div class="offset-md-5 d-flex justify-content-between">
              <div class="modal-title" id="exampleModalLabel">
                Agregar Tarjeta
              </div>
              <button
                type="button"
                class="close justify-content-end"
                data-dismiss="modal"
                aria-label="Close"
                #closeButton
                (click)="resetForm()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <form [formGroup]="cardForm" (ngSubmit)="onSaveCard()">
          
          <div class="row  mb-3">
            <div class="col-12">
              <label>Número de tarjeta</label>
              <input
                type="tel"
                class="form-control"
                id="cardNumber"
                placeholder="Escribe aquí el número de la tarjeta"
                formControlName="cardNumber"
                maxlength="19"
                (keyup)="formatCard($event)"
              />
            </div>
          </div>
          <div class="row  mb-3">
            <div class="col-12">
              <label>Fecha de expiración</label> 
            </div>
            <div class="col-4">
              <select
                class="form-control"
                id="expirationMonth"
                formControlName="expirationMonth"
              >
                <option value="" disabled>Mes</option>
                <option
                  *ngFor="let expirationMonth of expirationMonths"
                  [value]="expirationMonth"
                >
                  {{ expirationMonth }}
                </option>
              </select>
            </div>
            <div class="col-4">
              <select
                class="form-control"
                id="expirationYear"
                formControlName="expirationYear"
              >
                <option value="" disabled>Año</option>
                <option
                  *ngFor="let expirationYear of expirationYears"
                  [value]="expirationYear"
                >
                  {{ expirationYear }}
                </option>
              </select>
            </div>
            <div class="col-4 col-md-4">
              <input
                type="number"
                class="form-control"
                id="cvc"
                placeholder="CCV"
                formControlName="cvc"
              />
            </div>
          </div>
          <!-- jun -->
          <div class="row  mb-3">
            <div class="col-12 col-md-6">
              <label>Nombre de quien aparece en la tarjeta</label>
              <input
                type="text"
                class="form-control"
                id="cardHolder"
                placeholder="Escribe el nombre de la tarjeta"
                formControlName="cardHolder"
              />
            </div>
            <div class="col-12 col-md-6">
              <label>Tipo de documento titular</label>
              <select
                class="form-control"
                id="documentType"
                formControlName="documentType"
              >
                <option value="" disabled>Selecciona</option>
                <option
                  *ngFor="let documentType of documentTypes"
                  [value]="documentType.shortName"
                >
                  {{ documentType.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6">
              <label>No. de documento del titular</label>
              <input
                type="text"
                class="form-control"
                id="documentNumber"
                placeholder="Escribe aquí el número de documento"
                formControlName="documentNumber"
              />
            </div>
            <div class="col-12 col-md-6">
              <label>Correo electrónico</label>
              <input
                type="text"
                class="form-control"
                id="email"
                [(ngModel)]=emailInit
                placeholder="Escribe aquí el correo electrónico"
                formControlName="email"
              />
            </div>
          </div>
          
          <div class="row  mb-3">
            <div class="col-12 col-md-6">
              <label>Teléfono del titular</label>
              <input
                type="number"
                class="form-control"
                id="cardHolderPhone"
                placeholder="Escribe aquí"
                formControlName="cardHolderPhone"
              />
            </div>

            <div class="col-12 col-md-6">
              <label>País de facturación</label>
              <select
                class="form-control"
                id="billingCountry"
                formControlName="billingCountry"
              >
                <option value="" disabled>Selecciona</option>
                <option
                  *ngFor="let billingCountry of billingCountries"
                  [value]="billingCountry.name"
                >
                  {{ billingCountry.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col d-flex flex-column-reverse">
              <button
                type="button"
                class="btn btn-primary d-block m-auto px-5"
                type="submit"
                [disabled]="cardForm.invalid"
              >
                Agregar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
