import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user/user.service';
import { ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { User } from 'src/app/interfaces/user.interface';
import { Router } from '@angular/router';
import { main } from '@popperjs/core';

declare var $: $;

@Component({
  selector: 'app-salepoint-selector',
  templateUrl: './salepoint-selector.component.html',
  styleUrls: ['./salepoint-selector.component.scss']
})
export class SalepointSelectorComponent implements OnInit {

  public center = { lat: 4.7110, lng: -74.0721 };
  public loading = false;

  public readonly markerOptions = {
    icon: {
      url: "/assets/imgs/img-address/logo.png",
      scaledSize: {
        width: 45,
        height: 45
      }
    }
  }
  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true, // Ocultar todas las etiquetas predeterminadas
    zoomControl: true,
  };

  public zoomOut = 4.7;
  public zoomIn = 15;
  public _user;

  private _salePoints: any[];

  @Input() set salePoints(value: any[]) {
    if (value) {
      this._salePoints = value;
      this._salePoints.forEach(salePoint => {
        salePoint.direccion = salePoint.direccionSegmentada.nomenclatura + " " + salePoint.direccionSegmentada.numeroUno + " # " + salePoint.direccionSegmentada.numeroDos + " - " + salePoint.direccionSegmentada.numeroTres;
      });
    }
  }

  get salePoints(): any[] {
    return this._salePoints;
  }

  @Input() set user(value: User) {
    this._user = value;
    this.setInitialLocation();
  }

  get user(): User {
    return this._user;
  }

  public selectedSP: any;

  constructor(public router: Router,private userService: UserService, public toastr: ToastrService, @Inject(PLATFORM_ID) private platFormId) {

  }

  ngOnInit(): void {}

  changeSP(salePoint): void {
    this.selectedSP = salePoint;
  }

  openModal(event): void {
    $(event)?.click();
    this.closeModal();
  }

  nextModal(): void {}

  closeModal(): void {
    $('#closeSalePointSelectorModal')?.click();
  }

  setInitialLocation(): void {
    if (this.user?.puntoDeVentaTpv && this.salePoints) {            
      this.selectedSP = this.salePoints
        .filter(sp => sp.tpv == this.user.puntoDeVentaTpv)
        .sort((a, b) => b.tpv - a.tpv)[0];
    }
  }
  //Función para ordenar Alfabeticamente los nombres de los puntos de venta.
  sortSalePointsByName(): void {
    if (this.salePoints) {
    this.salePoints.sort((a, b) => a.nombre.localeCompare(b.nombre));
  }
  }

  updateUserInStore() {
    this.loading = true;
    if(this.user) {
      this.user.puntoDeVentaBarrio = this.selectedSP.barrio;
      this.user.puntoDeVentaBodega = this.selectedSP.bodega || '';
      this.user.puntoDeVentaCiudad = this.selectedSP.nombreMunicipio;
      this.user.puntoDeVentaCodMun = this.selectedSP.codigoMunicipio;
      this.user.puntoDeVentaDepto = this.selectedSP.codigoDepartamento;
      this.user.puntoDeVentaId = this.selectedSP.id;
      this.user.puntoDeVentaNombre = this.selectedSP.nombre;
      this.user.puntoDeVentaTpv = this.selectedSP.tpv;
      this.user.puntoDeVentaWhatsapp = this.selectedSP.whatsapp;

      this.user.tipoEntrega = "Recogida";

      // Update user
      this.userService
        .updateUserData(this.user)
        .then(() => {
          this.loading = false;
          if (window.innerWidth < 768) {
            // Tamaño de pantalla pequeño: alineación al centro
            this.toastr.success('El punto de venta fue agregado exitosamente.', null, {
              positionClass: 'toast-top-center',
            });
          } else {
            // Tamaño de pantalla grande: alineación a la derecha
            this.toastr.success('El punto de venta fue agregado exitosamente.', null, {
              positionClass: 'toast-top-right',
            });
          }

          let shippingMethod = {
            type: ShippingMethodType.IN_STORE,
            salePoint: this.selectedSP
          };
          let citySelected = {
            ciudad: this.selectedSP.nombreMunicipio,
            selection: true,
            salePoint: this.salePoints.filter(sp => sp.nombreMunicipio === this.selectedSP.nombreMunicipio)[0]
          }
          localStorage.setItem('citySelected', JSON.stringify(citySelected));
          localStorage.setItem('shippingMethod', JSON.stringify(shippingMethod));
          localStorage.setItem('cobertura', 'SI');
          this.userService.onSendGlobalShippingMethod(shippingMethod);
          this.router.navigate(['/']);
          this.closeModal();
        })
        .catch((error) => {
          this.loading = false;
          this.closeModal();
          this.toastr.error('No hemos podido cambiar la modalidad');
          console.log(`Error changing salepoint: ${error}`);
        });
    } else {
      if (isPlatformBrowser(this.platFormId)) {
        let shippingMethod: ShippingMethod = {
          type: ShippingMethodType.IN_STORE,
          salePoint: this.selectedSP
        };
        localStorage.setItem('shippingMethod', JSON.stringify(shippingMethod));
        localStorage.setItem('cobertura', 'SI');
        this.userService.onSendGlobalShippingMethod(shippingMethod);
        this.loading = false;
        if (window.innerWidth < 490) {
          // Tamaño de pantalla pequeño: alineación al centro
          this.toastr.success('El punto de venta fue agregado exitosamente.', null, {
            positionClass: 'toast-top-center',
          });
        } else {
          // Tamaño de pantalla grande: alineación a la derecha
          this.toastr.success('El punto de venta fue agregado exitosamente.', null, {
            positionClass: 'toast-top-right',
          });
        }
        this.router.navigate(['/']);
        this.closeModal();
      }
    }
  }

}
