import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { setFirebaseFilters } from 'src/app/global/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SalePointsService {
  baseURL = environment.backendUrl;
  headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}
  /**
   * Get the sale points from firebase
   */

  getAllSalePoints(filters: any[] = [], orderBy: string = '', limit: number = 0) {
    let params = setFirebaseFilters(filters, orderBy, limit);
    return this.http.get(`${this.baseURL}collections/getCollection/puntosDeVentaIntegracion`, { params });
  }

  getHollidays(): any {
    return this.http.get(this.baseURL + 'api/get-holiday', { headers: this.headers });
  }
}

export class SignalModalEmitterService {
  constructor() { }
  static onChange: EventEmitter<String> = new EventEmitter<String>();
  static sendSignal(signal: String) {
    this.onChange.emit(signal);
  }
}
