import { Component, OnInit } from '@angular/core';
import { SalePointsService } from 'src/app/core/services/sale-points/sale-points.service';
import { SeoService } from 'src/app/core/services/seo.service';

interface city { name: string; active: boolean };

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  /** Pagination variables */
  // Item's quantity per consult
  public itemsPerPage: number = 9;
  // current page in pagination
  public currentPage: number = 1;
  // Global total of items
  public totalItems: number = 0;
  // Sales points
  public salesPoints: any[] = [];
  // Render list
  public renderList: any[] = [];
  // Cities list
  public citiesList: city[] = [];
  // loading cards
  public mainLoading: boolean = true;
  /** Current city selected in filter */
  public activeBogota;

  public bogotaPoints = {
    norte: [],
    centro: [],
    sur: [],
  }

  public organizedPoints = {};

  constructor(private salePointsService: SalePointsService, private seoService: SeoService) {
    this.seoService.setSEO({
      title: 'Puntos de venta, Calypso del carible',
      description: 'Encuentra los puntos de venta más cercanos a tu localidad, Calypso del Caribe, lo mejor para ti.',
      main: false
    })
  }

  ngOnInit(): void {
    this.onGetSalesPoints();
  }

  /**
   * Gets sales points
   */
  onGetSalesPoints() {
    this.salePointsService.getAllSalePoints().toPromise().then((res:any) => {
      let puntos = res.map((doc) => {
        return doc as any;
      });
      // Getting sales points base on filters
      puntos = puntos.filter((punto) => !punto.distribuidor && punto.bodega);
      // Modify sales points (city, full address)
      this.salesPoints = puntos.map((punto) => {
        if (punto.direccionSegmentada.ciudad == 'El Rodadero') {
          punto.direccionSegmentada.ciudad = 'Santa Marta';
        }
        // Gets the cities attached to sales points
        if (
          !this.citiesList
            .map((city) => this.normalizeString(city.name))
            .includes(this.normalizeString(punto.direccionSegmentada.ciudad))
        ) {
          this.citiesList.push({
            name: punto.direccionSegmentada.ciudad,
            active: false,
          });
        }
        punto.direccionCompleta = this.getCompleteDirection(punto.direccionSegmentada);
        return punto;
      });
      this.organizeSalePoints();
    });
  }

  organizeSalePoints(): void {
    const organizedPoints: { [key: string]: { ciudad: string; puntosDeVenta: any[] } } = {};        
    for (let i = 0; i < this.salesPoints.length; i++) {
      const salePoint = this.salesPoints[i];
      const city = salePoint.nombreMunicipio;
  
      if (city === 'BOGOTA') {
        // Define the zones of Bogotá
        let zone;
        switch (salePoint.nombre) {
          case 'VEINTE DE JULIO SUPERMERCADO':
          case 'SUBA SUPERMERCADO':
          case 'GAITAN SUPERMERCADO':
            zone = 'BOGOTA - NORTE';
            break;
          case 'VEINTE DE JULIO SUPERMERCADO':
          case 'PRADO VERANIEGO SUPERMERCADO':
          case 'LA ESMERALDA SUPERMERCADO':
          case 'VILLA DEL PRADO SUPERMERCADO':
            zone = 'BOGOTA - CENTRO';
            break;
          case 'TUNJUELITO SUPERMERCADO':
          case 'SAN CRISTOBAL SUPERMERCADO':
            zone = 'BOGOTA - SUR';
            break;
          default:
            zone = 'BOGOTA'; // For sale points in Bogotá that do not have a defined zone
            break;
        }
        // Create the zone object if it doesn't exist
        if (!organizedPoints[zone]) {
          organizedPoints[zone] = { ciudad: zone, puntosDeVenta: [] };
        }
        // Add the sale point to the corresponding zone
        organizedPoints[zone].puntosDeVenta.push(salePoint);
      } else {
        // For cities outside Bogotá, use the city name as the zone
        if (!organizedPoints[city]) {
          organizedPoints[city] = { ciudad: city, puntosDeVenta: [] };
        }
        // Add the sale point to the corresponding city
        organizedPoints[city].puntosDeVenta.push(salePoint);
      }
    }
  
    // Convert organizedPoints to an array of typed objects
    let pointsArray: { ciudad: string; puntosDeVenta: any[] }[] = Object.values(organizedPoints);
  
    // Sort the array so that Bogotá cities come first
    pointsArray.sort((a, b) => {
      if (a.ciudad.startsWith('BOGOTA') && !b.ciudad.startsWith('BOGOTA')) {
        return -1;
      }
      if (!a.ciudad.startsWith('BOGOTA') && b.ciudad.startsWith('BOGOTA')) {
        return 1;
      }
      return 0; // Keeps the order if both or neither are from Bogotá
    });
  
    // Assign the sorted array to this.organizedPoints
    this.organizedPoints = pointsArray;    
    this.onGetRendering();
  }
  
  

  /**
   * Set the rending list
   */
  onGetRendering() {
    this.mainLoading = true;
    let salesPointsCopy = JSON.parse(JSON.stringify(this.organizedPoints));
    const activeCities = this.citiesList.filter(city => city.active); 
    if (activeCities.length >= 1) {
      const activeNormalized = activeCities.map(city => this.normalizeString(city.name));
      salesPointsCopy = salesPointsCopy.filter((item: any) => {
        const normalizedCity = this.normalizeString(item.direccionSegmentada.ciudad);
        return activeNormalized.includes(normalizedCity);
      });      
    }
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.totalItems = salesPointsCopy.length
    this.renderList = salesPointsCopy.slice(startIndex, endIndex);
    this.mainLoading = false;
  }

  // filters products by cities
  onNewCitiesFilter(event: city[]) {
    this.citiesList = event;
    this.onGetRendering();
  }

  // Normalizing string
  normalizeString(input: string): string {
    // Utiliza funciones integradas para normalizar el string (quitar tildes y convertir a minúsculas)
    return input
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  // Gets complete sales point direction
  getCompleteDirection(direccionSegmentada: any): string {
    return `${direccionSegmentada.nomenclatura} ${direccionSegmentada.numeroUno} # ${direccionSegmentada.numeroDos} - ${direccionSegmentada.numeroTres}`;
  }

  // Goes to previous page
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.onGetRendering();
    }
  }
  // Goes next page
  nextPage() {
    if (this.currentPage < Math.ceil(this.totalItems / this.itemsPerPage)) {
      this.currentPage++;
      this.onGetRendering();
    }
  }

  // Display an specific page
  onGoToPage(page: number) {
    this.currentPage = page;
    this.onGetRendering();
  }
}
