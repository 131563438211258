import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';
import { setFirebaseFilters } from 'src/app/global/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceRecetasService {

  private recipe = new BehaviorSubject('');
  private completeUrl = `${environment.backendUrl}collections/getCollection`;
  public selectedRecipe = this.recipe.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  getRecipes(collectionName: string, filters: any[] = [], orderBy: string = '', limit: number = 0) {
    let params = setFirebaseFilters(filters, orderBy, limit);
    return this.http.get(`${this.completeUrl}/${collectionName}`, { params }).toPromise();
  }

  setRecipe(recipe) {
    this.recipe.next(recipe);
  }
}
