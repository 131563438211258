import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { setFirebaseFilters } from 'src/app/global/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  private completeUrl = `${environment.backendUrl}collections`;

  constructor(private http: HttpClient) {}
  /**
   * Get all brands from firebase returns a Promise
   */
  getAllBrands(collectionName: string, filters: any[] = [], orderBy: string = '', limit: number = 0) {
    let params = setFirebaseFilters(filters, orderBy, limit);
    return this.http.get(`${this.completeUrl}/getCollection/${collectionName}`, { params }).toPromise();
  }

}
