import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { setFirebaseFilters } from 'src/app/global/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AbandonedShoppingCartService {

  private completeUrl = `${environment.backendUrl}collections`;
  constructor(
    private http: HttpClient
  ) {}

  getAbandonedShoppingCart(collectionName: string, filters: any[] = [], orderBy: string = '', limit: number = 0) {
    let params = setFirebaseFilters(filters, orderBy, limit);
    return this.http.get(`${this.completeUrl}/getCollection/${collectionName}`, { params });
  }

  createAbandonedCart(abandonedCart) {
    return this.http.post(`${this.completeUrl}/postCollection/carritoAbandonado`, {abandonedCart}).toPromise();
  }

  updateAbandonedCart(id, abandonedCart) {
    return this.http.put(`${this.completeUrl}/updateCollection/carritoAbandonado/${id}`, {abandonedCart});
  }

  deleteAbandonedCart(id) {
    return this.http.delete(`${this.completeUrl}/deleteDocCollection/carritoAbandonado/${id}`);
  }

}
