import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from 'src/app/interfaces/orders.interface';
import { ProductsInfo } from 'src/app/interfaces/products.interface';
import { User } from 'src/app/interfaces/user.interface';
import { hideIdInfo } from 'src/app/global/utils';
import { Router } from '@angular/router';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-order-price-info',
  templateUrl: './order-price-info.component.html',
  styleUrls: ['./order-price-info.component.scss']
})
export class OrderPriceInfoComponent implements OnInit {

  @Input() productsInfo: ProductsInfo;

  @Input() order: Order;

  @Input() credits: number;

  @Input() isVariableWeight:boolean;

  public creditsUsed = 0;

  public useCredits = true;


  public freeDelivery = false;

  public deliveryCost = 0;

  @Output() onCreditsUsed = new EventEmitter<number>();

  public currentUser: User;

  public detailUrl: boolean;

  constructor(
    private router: Router, 
    private localStorage: LocalstorageService
  ) { }

  ngOnInit(): void { 
    this.currentUser = JSON.parse(this.localStorage.getItem('user'));
    if (this.router.url.includes('/order/order-detail')) {
      this.detailUrl = true;
    }
  }

  getTotal(): number {
    let total = 0;
    /** Checks subtotal products to determinate free delivery */
    if (this.checkIvaDelivery()) {
      this.freeDelivery = true;
      this.deliveryCost = 0;
      total = this.productsInfo.subtotal;
    } else {
      this.freeDelivery = false;
      this.deliveryCost = this.productsInfo?.total - this.productsInfo?.subtotal;
      total = this.productsInfo?.total;
    }
    // Se chequea si se quieren utilizar los créditos
    if (this.useCredits) {
      if (this.credits <= total) {
        this.creditsUsed = this.credits;
        total -= this.credits;
      } else {
        this.creditsUsed = total;
        total -= this.creditsUsed;
      }
    } else {
      this.creditsUsed = 0;
    }

    if (this.productsInfo?.totalWithCredit) {
      total = this.productsInfo.totalWithCredit;
    }
    return total;
  }

  /**
   * Check IVA value to determinate free delivery
   * ! se deja comentado por si se necesita de nuevo la validacion mediante el impuesto
   */
  checkIvaDelivery() {
    const finalSubtotal = this.productsInfo?.subtotal;
    // const finalSubtotal = this.productsInfo?.products.reduce(
    //   (prev, curr) => prev + curr.subtotal * curr.cantidad,
    //   0
    // );
    return finalSubtotal > 80000;
  }

  /**
   * Emite el valor seleccionado cuando cambia el estado de usar créditos
   * @param use si se usan o no los créditos
   */
  sendUseCredits(): void {
    this.getTotal();
    this.onCreditsUsed.emit(this.creditsUsed);
  }

  hideNit(id: number): string {
    return hideIdInfo(id);
  }
}
