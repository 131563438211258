import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/core/services/orders/orders.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart/shopping-cart.service';
import { Order } from 'src/app/interfaces/orders.interface';
import { User } from 'src/app/interfaces/user.interface';
import { SalePointsService } from 'src/app/core/services/sale-points/sale-points.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss'],
})
export class WhatsappButtonComponent implements OnInit {
  @ViewChild('dialogDetailWpp', { static: true }) modal: ElementRef;
  PENDING_STATUS = 'Pendiente';
  IN_PROGRESS_STATUS = 'Pedido en curso';
  BOUGHT_STATUS = 'Comprado';
  ordersNumber: number;
  ordersType: string = 'ACTIVE';
  pickupCode: string;
  activeOrders: Order[];
  isPlan3000: boolean;
  isNegocio: boolean;
  shippingMethod: any;
  shippingMethodsubscriber: any;
  citySelected: any;
  numberWPP: string = '3046707272';
  constructor(
    private shoppingCartService: ShoppingCartService,
    private ordersService: OrdersService,
    private router: Router,
    private salePointsService: SalePointsService,
    private modalService: NgbModal,
    private userService: UserService,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId
  ) { }

  ngOnInit(): void {
    this.shippingMethodsubscriber = this.userService.sendGlobalShippingMethodObservable.subscribe((res) => {
      let salePointWarehouse
      this.shippingMethod = res
      if (this.shippingMethod) {
        if (this.shippingMethod.type === 'Domicilio' && this.shippingMethod.address !== undefined) {
          salePointWarehouse = this.shippingMethod.address.inventario;
        } else if (this.shippingMethod.type === 'Recogida') {
          salePointWarehouse = this.shippingMethod.salePoint.bodega;
        }
        if (salePointWarehouse !== undefined && salePointWarehouse !== null) {
          const filters = [{ field: 'bodega', operator: '==', value: salePointWarehouse }];
          this.salePointsService.getAllSalePoints(filters).toPromise().then((res: any) => {
            res.forEach((salePointResult) => {
              const salePoint = salePointResult;
              if (salePoint.distribuidor && salePoint.whatsapp) {
                this.numberWPP = salePoint.whatsapp.toString()
              } else {
                this.numberWPP = '3046341295'
              }
            })
          })
        }
      }
    })
    this.userService.initShippingMethodData()
    if (isPlatformBrowser(this.platFormId)) {
      const user = JSON.parse(this.localStorage.getItem('user')) as User;
      if (user != null && user.idListaPrecios && user.idPlan3000) {
        this.isPlan3000 = true;
      }
      if (user != null && user.tipoCuenta == 'Negocio') {
        this.isNegocio = true;
      }
    }
    this.ordersService.orders.subscribe((orders: Order[]) => {
      this.activeOrders = orders.filter((order) => {
        if (order.tipoEntrega === 'Recogida' && this.checkIsActive(order.estado)) {
          this.pickupCode = order.codigoRetiro;
        }
        return this.checkIsActive(order.estado);
      });
      this.ordersNumber = this.activeOrders.length;
      if (this.ordersNumber === 0) {
        this.shoppingCartService.cart$.subscribe((products) => {
          this.ordersNumber = products.length;
          this.ordersType = 'CART';
        });
      }
    });
  }

  /**
   * Check if order is active
   * @param orderStatus Status from order
   */
  checkIsActive(orderStatus: string): boolean {
    return (
      orderStatus === this.IN_PROGRESS_STATUS ||
      orderStatus === this.PENDING_STATUS ||
      orderStatus === this.BOUGHT_STATUS
    );
  }

  /**
   * Open WhatsApp modal
   */
  onWP(): void {
    this.modalService.open(this.modal)
  }

  /**
   * Function to send wpp message
   * @param typeMsg 
   */
  onSendWpp(typeMsg) {
    if (typeMsg == 'support') {
      var urlWpp = 'https://api.whatsapp.com/send?phone=57' + this.numberWPP + '&text=Hola,%20tengo%20dificultades%20con%20Calypso%20WEB,%20requiero%20ayuda%20con%20mi%20pedido.'
    } else {
      var urlWpp = 'https://api.whatsapp.com/send?phone=573046707272&text=Hola,%20tengo%20una%20solicitud%20en%20Calypso'
    }
    window.open(
      urlWpp,
      '_system',
      'location=yes'
    );
  }

  /**
   * Manage click on in progress order item
   */
  onInProgressOrderItem(): void {
    if (this.ordersNumber === 1 && this.ordersType === 'ACTIVE') {
      const orderId = this.activeOrders.slice(0, 1).shift().id;
      this.router.navigate(['/order/order-detail', orderId]);
    } else if (this.ordersNumber > 1 && this.ordersType === 'ACTIVE') {
      this.router.navigate(['/user-profile/orders-info']);
    } else {
      this.shoppingCartService.changeDeployShoppingCart({
        data: { success: true },
      });
    }
  }

  /**Closes active modal */
  close() {
    this.modalService.dismissAll();
  }
}
