import { Component, OnInit, Input, PLATFORM_ID, Inject, ViewChild, ElementRef, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef, OnChanges } from '@angular/core';
import { BrandsService } from 'src/app/core/services/brands/brands.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit, AfterViewChecked {

  _brandNames: string[];

  get brandNames() {
    return this._brandNames;
  }

  @Input() set brandNames(value: any) {
    this._brandNames = value;
    this.updateBrands();
  }
  brands: any[] = [];

  /** Stores selected brand */
  @Input() selectedBrand: any;
  @Output() brandChange = new EventEmitter<any>();

  /**Saved scroll left position */
  scrollLeft: number = 0;
  /**Get container width */
  containerWidth: number;
  /**Get scroll container width */
  scrollWidth: number;
  /**Get the brand width */
  widthBrand: number;

  @ViewChild('scrollable') scrollable!: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platFormId, private brandsService: BrandsService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.updateBrands();
  }

  ngAfterViewChecked(): void {
    this.scrollWidth = this.scrollable?.nativeElement.scrollWidth;
    this.containerWidth = this.scrollable?.nativeElement.offsetWidth;
    this.widthBrand = (document.getElementsByClassName('brand-btn').item(0) as HTMLElement)?.offsetWidth;
    this.cd.detectChanges();
  }
  /**
   * Scrolling to brand container. Scroll to right
   */
  onScrollRight() {
    this.scrollLeft += this.widthBrand;
    if (this.scrollLeft > this.scrollWidth - this.containerWidth) this.scrollLeft = this.scrollWidth - this.containerWidth;
    if (this.scrollable) this.scrollable.nativeElement.scrollLeft = this.scrollLeft;
  }

  /**
  * Scrolling to brand container. Scroll to left
  */
  onScrollLeft() {
    this.scrollLeft -= this.widthBrand;
    if (this.scrollLeft < 0) this.scrollLeft = 0;
    if (this.scrollable) this.scrollable.nativeElement.scrollLeft = this.scrollLeft;
  }

  /**
   * Update brands
   */
  updateBrands(): void {
    this.brands = [];
    this.brandNames.forEach(name =>
      this.brandsService.getAllBrands('marcas', [
        { field: 'nombre', operator: '==', value: name }
      ]).then((data: any) => {
        const fetchedBrand = data.find((brandData: any) => brandData.nombre === name);
        if (fetchedBrand && !this.brands.find(b => b.nombre === fetchedBrand.nombre)) {
          this.brands.push(fetchedBrand);
        }
      })
    );
  }

  /**
   * Selects the specified brand
   * @param brand
   */
  selectBrand(brand): void {
    if (this.selectedBrand === brand) {
      this.selectedBrand = undefined;
    } else {
      this.selectedBrand = brand;
    }
    this.brandChange.emit(this.selectedBrand);
  }
}
