<div class="banner">
    <div class="HomeMainComponent">
        <div class="row main-row">
            <div class="logo-calypso">
                <img src="../../../assets/icons/header/calypso-logo-renew.svg" class="img" (click)="goHome()" />
            </div>
            <h1 class="text-1">Canal de transparencia</h1>
        </div>
    </div>
</div>
<div class="transparency-container">
    <div class="card">
        <div class="card-body">
            <div class="card-head text-center h3">
                <img class="angle-left" (click)="goHome()" src="/assets/icons/user-profile/keyboard_arrow_left.svg"
                    alt="Icono izquierda" />
                Política de Transparencia y Ética Empresarial
            </div>
            <ng-container *ngIf="!formSubmitted">
                <p class="card-text">¿Qué debo reportar a través del Canal de Transparencia? Si conoce o es testigo de
                    una
                    infracción o
                    incumplimiento a nuestra Política de Transparencia y Ética Empresarial, o eventos relacionados con
                    corrupción, soborno transnacional, fraude, o cualquier otro delito, puede realizar el reporte por
                    medio
                    del siguiente formulario.</p>
                <form [formGroup]="transparencyForm">
                    <div class="row">
                        <div class="col-6 input-container">
                            <label class="label-form">Nombre</label>
                            <input type="text" class="form-control input-form" placeholder="Escribe aquí"
                                formControlName="name" />
                        </div>
                        <div class="col-6 input-container">
                            <label class="label-form">Apellido</label>
                            <input type="text" class="form-control input-form" placeholder="Escribe aquí"
                                formControlName="lastname" />
                        </div>
                        <div class="col-6 input-container">
                            <label class="label-form">Correo electrónico</label>
                            <input type="email" class="form-control input-form" placeholder="Escribe aquí"
                                formControlName="email" />
                        </div>
                        <div class="col-6 input-container">
                            <label class="label-form">Teléfono</label>
                            <input type="number" class="form-control input-form" placeholder="Escribe aquí"
                                formControlName="phone" />
                        </div>
                        <div class="col-6 input-container">
                            <label class="label-form">Ciudad <span style="color: #E73233;">*</span></label>
                            <input type="text" class="form-control input-form" placeholder="Escribe aquí"
                                formControlName="city"
                                [ngClass]="{'is-invalid': transparencyForm.get('city').touched && transparencyForm.get('city').invalid}" />
                            <div class="invalid-feedback" style="color: #E73233;">Este campo es obligatorio.</div>
                        </div>
                        <div class="col-12 input-container">
                            <label class="label-form">Descripción de la denuncia o hechos <span
                                    style="color: #E73233;">*</span> </label>
                            <textarea class="form-control input-form" name="description" id="description" cols="1"
                                #report rows="5" formControlName="message" placeholder="Escribe aqui"
                                style="height: auto;" (input)="characterCount()" maxlength="600"
                                [ngClass]="{'is-invalid': transparencyForm.get('message').touched && transparencyForm.get('message').invalid}">
                            </textarea>
                            <p class="d-flex justify-content-end">{{ charactersLeft }}</p>
                            <div class="invalid-feedback" style="color: #E73233;">Este campo es obligatorio.</div>
                        </div>
                        <div class="col-12 input-container d-flex flex-column">
                            <label class="label-form m-0">Evidencia (opcional)</label>
                            <span class="mt-1 mb-1">Archivos permitidos JPEG, PNG y PDF.</span>
                            <div class="d-flex flex-column ">
                                <label for="userFile" class="btn btn-upload">Cargar archivo</label>
                                <input type="file" id="userFile" #userFile name="userFile" formControlName="userFile"
                                    style="display: none;" accept="image/png, image/jpeg, application/pdf"
                                    (change)="onFileSelected($event)" />
                                <span class="file-name" id="fileName">{{ fileName ||'Ningún archivo seleccionado'}}</span>
                            </div>
                        </div>
                        <div class="col-12 input-container">
                            <div class="container">
                                <input 
                                    class="form-check-input" 
                                    type="checkbox" 
                                    formControlName="policyTerms" 
                                    [ngClass]="{'is-invalid': transparencyForm.get('policyTerms')?.touched && transparencyForm.get('policyTerms')?.invalid}" />
                                <label class="form-check-label text-stl" for="defaultCheck1">
                                    Autorizo el <u>tratamiento de datos personales</u> <span style="color: #E73233;">*</span>
                                </label>
                            </div>
                            
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="formSubmitted">
                <p class="card-text">Gracias por tomarte el tiempo de enviarnos tu denuncia. A partir de este momento,
                    nuestro equipo se encargará de gestionar el reporte utilizando la información proporcionada.</p>
            </ng-container>
        </div>
        <button class="btn btn-primary confirm-btn custom-btn" type="submit" *ngIf="!formSubmitted" (click)="sendReport()" [disabled]="!transparencyForm.valid">
            Enviar reporte
        </button>
        <button *ngIf="formSubmitted" class="btn btn-primary confirm-btn custom-btn" (click)="newReport()">
            Radicar nuevo reporte
        </button>
    </div>
</div>
