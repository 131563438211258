import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalFunctionsService } from 'src/app/core/services/global-functions/global-functions.service';

@Component({
  selector: 'app-canal-transparencia',
  templateUrl: './canal-transparencia.component.html',
  styleUrls: ['./canal-transparencia.component.scss']
})
export class CanalTransparenciaComponent implements OnInit {
  /** Reference to the textarea element in the DOM */
  @ViewChild('report') miTextarea: ElementRef;
  public formSubmitted = false;

  public selectedFile: File | null = null;
  public fileName: string | null = null;

  public transparencyForm = new FormGroup({
    name: new FormControl(''),
    lastname: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    userFile: new FormControl(''),
    city: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    policyTerms: new FormControl(false, Validators.requiredTrue),
  });

  /** Set the maximum number of characters for the comment section */
  public maxCharacters:number = 600;
  /** Store the remaining characters */
  public charactersLeft:number;
  /** textArea empty */
  public textareaEmpty: boolean = true;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private globalFunctionsService: GlobalFunctionsService,
  ) { }

  ngOnInit(): void {
  }

  newReport() {
    this.formSubmitted = false;
    this.transparencyForm.reset();
    this.fileName = null;
  }

  goHome() {
    this.router.navigate(['/'])
  }

  sendReport() {
    const formData = new FormData();
    formData.append('name', this.transparencyForm.get('name').value);
    formData.append('lastname', this.transparencyForm.get('lastname').value);
    formData.append('email', this.transparencyForm.get('email').value);
    formData.append('city', this.transparencyForm.get('city').value);
    formData.append('phone', this.transparencyForm.get('phone').value);
    formData.append('message', this.transparencyForm.get('message').value);
    formData.append('userFile', this.selectedFile);

    this.formSubmitted = true;
    window.scrollTo(0, 0);
    
    this.globalFunctionsService.sendForm('transparency-chanel', formData).pipe(
      catchError((error: any) => {
        this.toastr.error(error.error.msg);
        return throwError(error);
      })
    ).subscribe((res: any) => {
      this.toastr.success(res.msg);
      this.transparencyForm.reset();
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0]; // Obtiene el nombre del archivo seleccionado
      this.fileName = input.files[0].name; // Obtiene el nombre del archivo seleccionado
    } else {
      this.selectedFile = null; // Resetea el nombre si no hay archivo
    }
  }

  characterCount() {
    const textAreaContent = this.miTextarea.nativeElement.value;
    this.charactersLeft = this.maxCharacters - textAreaContent.length;
    this.textareaEmpty = this.transparencyForm.get('message').value.trim() === '';
  }
}
