import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/interfaces/products.interface';
import { GlobalFunctionsService } from '../../core/services/global-functions/global-functions.service';
import * as _ from 'lodash';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Pipe({
  name: 'productSearchPipe',
})
export class ProductSearchPipePipe implements PipeTransform {
  constructor(
    private globalFunctions: GlobalFunctionsService,
    private localStorage: LocalstorageService
  ) {}

  transform(products: any[], search: string, salePoint: String): Product[] {
    let shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    let bodega;

    if (!shippingMethod) {
      bodega = '';
    } else if (shippingMethod.type === 'Domicilio') {
      if (shippingMethod.address) bodega = shippingMethod.address.inventario;
    } else {
      bodega = shippingMethod.salePoint.bodega;
    }

    // Normaliza el texto de búsqueda para eliminar tildes
    const normalizedSearch = this.normalizeText(search);

    for (let product of products) {
      product.nombreSearch = this.normalizeText(product.nombre);
    }

    if (!search || search === '') {
      let doubleFilteredProducts = [];
      if (bodega === '') {
        doubleFilteredProducts = products;
      } else {
        products.forEach((element) => {
          if (element['inventarioBodega'][bodega] !== 0) {
            doubleFilteredProducts.push(element);
          }
        });
      }
      doubleFilteredProducts = _.orderBy(doubleFilteredProducts, ['index'], ['asc']);
      return doubleFilteredProducts;
    } else {
      const filteredProducts = products.filter((product) =>
        product.nombreSearch.includes(normalizedSearch)
      );
      let doubleFilteredProducts = [];
      if (bodega === '') {
        doubleFilteredProducts = filteredProducts;
      } else {
        filteredProducts.forEach((element) => {
          if (element['inventarioBodega'][bodega] !== 0) {
            doubleFilteredProducts.push(element);
          }
        });
      }
      doubleFilteredProducts = _.orderBy(doubleFilteredProducts, ['index'], ['asc']);
      return doubleFilteredProducts;
    }
  }

  // Función para normalizar texto y eliminar acentos
  normalizeText(text: string): string {
    return text
      ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      : '';
  }
}
