import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FooterService } from '../core/services/footer/footer.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { ShoppingCartService } from '../core/services/shopping-cart/shopping-cart.service';
import { delay, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { SalePointsService, SignalModalEmitterService } from '../core/services/sale-points/sale-points.service';
import { Router } from '@angular/router';
import { LocalstorageService } from '../core/services/localstorage.service';
declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public mdlShoppingCart: boolean = false;
  public subcribeShoppingcart$: Subscription;
  public message: string = '';
  public txtButton: string = '';
  private modeSel: string = '';

  @Output('openModal') modalEmmitter = new EventEmitter();
  /**
   * ReplaySubject to destroy all suscription on component onDestroy
   */
  private destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  shouldShowFooter = true;
  constructor(
    private shoppingCartService: ShoppingCartService,
    private footerService: FooterService,
    private salePointsService: SalePointsService,
    private router: Router,
    private localStorage: LocalstorageService,
  ) { }

  ngOnInit(): void {
    this.footerService.shoulShow
      .pipe(takeUntil(this.destroyed), delay(0))
      .subscribe((shouldShow) => {
        this.shouldShowFooter = shouldShow;
      });
    // subcribcion for deploy the shopping cart
    this.subcribeShoppingcart$ = this.shoppingCartService.deployShoppingCart.subscribe(
      (data: any) => {
        if (data.success) {
          this.mdlShoppingCart = true;
          //this.showModal();
          this.checkModeDelivery();
        }
      }
    );
  }

  public closeShoppingCard(event?: any): void {
    this.mdlShoppingCart = false;
    if (this.router.url == '/checkout' && event === 0) {
      this.router.navigate(['/']);
    }
  }

  /**
   * Muestra el modal de la preguntas.
   */
  showModal(): void {
    $('#modeDeliveryDisableModal').modal('show');
  }

  /**
   * Cierra el modal de la preguntas.
   */
  closeModal(): void {
    $('#modeDeliveryDisableModal').modal('hide');
  }

  /**
   * Checa el modo de entrega.
   */
  chooseModeDelivery(): void {
    this.closeModal();
    SignalModalEmitterService.sendSignal(this.modeSel);
    this.closeShoppingCard();
  }

  private checkModeDelivery() {
    // Se consulta la bodega que esta localmente.
    let shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    let bodega = '';
    if (shippingMethod) {
      if (shippingMethod.type === 'Recogida') {
        bodega = shippingMethod.salePoint.bodega;
      } else {
        if(shippingMethod.address){
          bodega = shippingMethod.address.inventario;
        }
      }
      // Se consulta el punto de venta remoto.
      const filters = [{ field: 'bodega', operator: '==', value: bodega }];
        this.salePointsService.getAllSalePoints(filters).toPromise().then((data:any) => {
        if (data.size === 1) {
          let salePoint = data;
          // Se valida que modo de entrega esta activo.
          if (salePoint.modosEntrega){
            // Se muestra el modal, como mensaje correspondiente.
            if (shippingMethod.type === 'Recogida' && salePoint.modosEntrega.recogida === false) {
              this.message = `El modo de recogida tienda está DESHABILITADO en la tienda de ${salePoint.nombre}.\n\nPor favor, escoge o registra una dirección para continuar.`;
              this.txtButton = 'Escoge o registra un dirección';
              this.modeSel = '#openAddressListModal';
              this.showModal();
            } else if (shippingMethod.type === 'Domicilio' && salePoint.modosEntrega.domicilio === false) {
              this.message = `El modo de domicilio está DESHABILITADO en la tienda de ${salePoint.nombre}.\n\nPor favor, escoge un  punto de venta para continuar.`;
              this.txtButton = 'Escoge un punto de venta';
              this.modeSel = "#openSalePointSelector";
              this.showModal();
            }
          }
        }
      }
      ).catch((error) => {
        console.log(error);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed?.next(true);
    this.destroyed?.complete();
  }
}