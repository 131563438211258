import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { SalePointsService, SignalModalEmitterService } from 'src/app/core/services/sale-points/sale-points.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { SalePoint } from 'src/app/interfaces/salePoints.interface';
import { User } from 'src/app/interfaces/user.interface';
import * as $ from 'jquery';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-address-main',
  templateUrl: './address-main.component.html',
  styleUrls: ['./address-main.component.scss'],
})
export class AddressMainComponent implements OnInit {
  /**Saved user information */
  salePoints: SalePoint[];
  pointGPS: object;
  coverage: boolean;
  address: any;
  salePoint: SalePoint;
  user: User;
  constructor(
    private salePointsService: SalePointsService,
    private userService: UserService,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId
  ) {
    SignalModalEmitterService.onChange.subscribe({
      next: (event: string) => {
        this.openModal(event);
      }
    })
  }

  ngOnInit(): void {
    this.getSalePoints();
    let userLogged = JSON.parse(this.localStorage.getItem('userLogged'));
    if (userLogged) {
      this.userService.getUserIdLogged().subscribe((userId) => {
        if (userId) {
          this.getUserInfo(userId);
        }
      });
    }
  }

  /**
   * Get user information
   * @param userId User id
   */
  getUserInfo(userId: string): void {
    this.userService.getUserbyId(userId).toPromise().then((doc:any) => {
      this.user = doc as User;
    });
  }

  /**
   *Get sale point information
   */
  async getSalePoints() {
    this.salePointsService.getAllSalePoints().toPromise().then((res:any) => {
      this.salePoints = res
        .map((doc: SalePoint) => {
          let data = doc as SalePoint;
          return data;
        })
        .filter((sp) => sp.bodega && sp.zonaCobertura && sp.lat && sp.lng && sp.tpv);
    });
  }

  /**
   * Event which receives geolocation information
   * @param event Return the geolocation data
   */
  getGeolocationData(event) {
    this.coverage = event.coverage;
    this.pointGPS = event.pointGPS;
    this.address = event.address;
  }

  /**
   * Event which receives addres information.
   * @param event Return the address data
   */
  getSalePointData(event) {
    this.coverage = event.coverage;
    this.pointGPS = event.pointGPS;
    this.salePoint = event.salePointInfo;
  }

  /**
   * Opens the specified modal on the event
   * @param event Return the modal to open
   */
  openModal(event) {
    $(event)?.click();
  }
}
