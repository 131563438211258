<div class="modal fade" id="addressListModal">
  <div class="modal-dialog show2">
    <div class="modal-content">
      <a (click)="openModal('#openDeliverySelector')" class="back-btn">
        <img src="../../../assets/icons/header/arrow_left2.png" alt="icon-back" class="icon-img" />
      </a>
      <a (click)="closeAddressListModal()" class="modal-close">
        <img src="../../../assets/icons/header/clear.png" alt="icon-close" class="icon-button icon-img" />
      </a>
      <div class="modal-body">
        <div class="col-12 col-title">
          <p class="title h3">Indica una dirección para la entrega</p>
        </div>
        <div class="col-12 col-subtitle">
          <p class="subtitle h6">Direcciones guardadas</p>
        </div>
        <div *ngIf="addressList && addressList.length > 0" class="form-check">
          <div *ngFor="let address of addressList; let i = index" class="check">
            <input
              class="form-check-input"
              type="radio"
              name="radioAddress"
              id="radioAddress{{ i }}"
              [checked]="address?.principal == true"
              [value]="address"
              [(ngModel)]="addressSelect"
            />
            <label class="form-check-label" for="radioAddress{{ i }}">
              {{ address?.nomenclatura }} {{ address?.numeroUno }} # {{
                address?.numeroDos
              }}
              - {{ address?.numeroTres }} {{ address?.indicaciones }} <br> <span class="city-tag">{{ address?.ciudad }}</span>
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <a
              class="add-address"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#coverageFormModal"
            >
              Agregar nueva dirección
        </a>
          <button
            class="btn btn-primary confirm-btn"
            (click)='changeAddress()'
            [disabled]='clicRequest'>
              Continuar
            </button>
      </div>
        <!-- Button which close this modal -->
        <button
          [hidden]="true"
          id="closeAddressListModal"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>
