import { Component, Input, OnInit, PLATFORM_ID, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Category, Product } from 'src/app/interfaces/products.interface';
import { isPlatformBrowser } from '@angular/common';
import { User } from 'src/app/interfaces/user.interface';
import { Address, SalePoint, ShippingMethod, ShippingMethodType } from 'src/app/interfaces/salePoints.interface';
import { Subscription, Observable } from 'rxjs';
import { SalePointsService } from 'src/app/core/services/sale-points/sale-points.service';
import * as _ from 'lodash';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-products-container',
  templateUrl: './products-container.component.html',
  styleUrls: ['./products-container.component.scss'],
})
export class ProductsContainerComponent implements OnInit, OnDestroy {
  /**
   * Saved all products
   */
  allProducts: Product[] = [];
  /**
   * Search parameter
   */
  searchProduct = '';
  /**
   * Saved category selected
   */
  categorySelected: any = {
    nombre: 'Todos los productos',
    productos: [],
    slug: 'todos',
  };
  productsShow: Product[] = [];
  brandNames: string[] = [];
  selectedBrand: any;
  allCategories: any = {
    nombre: 'Todos los productos',
    productos: [],
    slug: 'todos',
  };
  /**
   * Saved categories with products
   */
  categories: Category[] = [];
  /**
   * Products observable
   */
  productsSubscription: Subscription;
  /**
   * Categories observable
   */
  categoriesSubscription: Subscription;
  /**
   * Saved user information
   */
  salePoints: SalePoint[];
  public user: User = {
    email: '',
    celular: '',
    createdAt: NaN,
    nombre: '',
    uid: '',
    tipo: '',
    aceptoTerminos: false,
    direccionIp: '',
  };
  /**
   * User id
   */
  public userId: string;

  public obser: Subscription;
  public subscribeShippingMethodGlobal: Subscription;

  @ViewChild('containerCatProds') contenedor;

  constructor(
    private userService: UserService,
    private productsService: ProductsService,
    private activatedRoute: ActivatedRoute,
    private salePointsService: SalePointsService,
    private localStorage: LocalstorageService,
    @Inject(PLATFORM_ID) private platFormId
  ) {
    const userData = JSON.parse(this.localStorage.getItem('user')) as User;
    let selectedPromiseProduct: Promise<Product[]>;
    if (userData && userData.tipoCuenta === 'B2B') {
      selectedPromiseProduct = this.productsService.getProductsB2B();
    } else {
      selectedPromiseProduct = this.productsService.getAllProductsAPI();
    }
    
    this.productsService.getAllProductsAPI().then((products: Product[]) => {
      this.categorySelected = {
        nombre: 'Todos los productos',
        productos: products,
        slug: 'todos'
      };
      this.setBrands(products);
      this.productsShow = products;
      if (this.user.uid) {
        this.productsShow = this.filteredProducts(this.productsShow);
      }
    });
    
    selectedPromiseProduct.then((products: Product[]) => {
      if (this.categorySelected.slug === 'todos' && this.categorySelected.productos.length === 0) {
        this.categorySelected.productos = products;
        this.productsShow = products;
        if (this.user.uid) {
          this.productsShow = this.filteredProducts(this.productsShow);
        }
      }
    });
  }

  ngOnInit(): void {
    // Functions are controlled in the browser. The user control for logged in
    this.getSalePointOperation();
    if (isPlatformBrowser(this.platFormId)) {
      this.initializeUserAndProductInfo();
      this.observableAddress();
      this.obser = this.productsService.sendSearchParamObservable.subscribe((searchParam) => {
        this.searchProduct = searchParam;
      });
    }
  }

  ngOnDestroy(): void {
    this.obser?.unsubscribe();
    this.subscribeShippingMethodGlobal?.unsubscribe();
  }

  /**
   * Get products by type account
   * @param products 
   * @returns 
   */
  filteredProducts(products) {
    let productsFiltered: Product[] = []
    products.forEach((product) => {
      if (product.tipoCuenta) {
        if (product.tipoCuenta == this.user.tipoCuenta || product.tipoCuenta == 'Todos' || this.user.tipoCuenta == undefined || this.user.tipoCuenta == null) {
          productsFiltered.push(product)
        }
      } else {
        productsFiltered.push(product)
      }
    })
    products = productsFiltered
    return products
  }

  /**
   * Activates the direction observable
   */
  observableAddress(): void {
    this.subscribeShippingMethodGlobal = this.userService.sendGlobalShippingMethodObservable.subscribe(
      (shippingMethod) => {
        this.getCategoriesWithProductsAPI(shippingMethod, true);
      }
    );
  }

  getSalePointOperation(): void {
    this.salePointsService.getAllSalePoints().toPromise().then((res:any) => {
      this.salePoints = res.map((doc) => {
        let data = doc as SalePoint;
        return data;
      }).filter(sp => sp.bodega && sp.zonaCobertura && sp.lat && sp.lng && sp.tpv);
    }
    );
  }

  /**
   * Get user information
   * @param userId User id
   */
  getUserInfo(userId: string): void {
    this.userService.getUserbyId(userId).subscribe((doc) => {
      this.user = doc as User;
      let address: Address;
      let shippingMethod: ShippingMethod;
      // Send a address if there is valid and coveraged address
      if (address && this.user.tipoEntrega !== 'Recogida') {
        address = this.user.direcciones.filter(
          (address) => address.principal === true
        )[0];
        shippingMethod = {
          type: ShippingMethodType.SHIPPING,
          address,
        };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      } else {
        shippingMethod = { type: ShippingMethodType.SHIPPING };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      }
      if (this.user.tipoEntrega && this.user.tipoEntrega === 'Recogida') {
        shippingMethod = {
          type: ShippingMethodType.IN_STORE,
          salePoint: this.salePoints.filter((sp) => sp.id === this.user.puntoDeVentaId)[0],
        };
        this.getCategoriesWithProductsAPI(shippingMethod, false);
      }
      this.getShippingMethodActivated(shippingMethod);
    });
  }

  /**
   * Get categories with products according to if there is shipping method with coverage.
   * @param shippingMethod User shipping method
   */
  async getCategoriesWithProductsAPI(shippingMethod: ShippingMethod, isObservable: boolean) {
    this.categories = [];
    let categories = this.productsService.allCategoriesResponse ?? await this.productsService.getAllCategoriesAPI();
    let allProducts = this.productsService.allProductsResponse ?? await this.productsService.getAllProductsAPI();
    
    if (allProducts  === undefined && categories === undefined) {
      allProducts = await this.productsService.getAllProductsAPI();
      categories = await this.productsService.getAllCategoriesAPI();
    }

    this.allProducts = allProducts;

    if (allProducts && categories) {      
      this.productsService.getCategoriesAndProductsAPI(categories, allProducts).then((data) => {
        if (isObservable) {          
          const result = this.productsService.filterCategoriesByWarehouse(data, shippingMethod);
          this.categories = _.orderBy(result, ['index', 'nombre'], ['asc', 'asc']);
          this.getActivatedCategory(this.categories);
        } else {
          this.categories = _.orderBy(data, ['index', 'nombre'], ['asc', 'asc']);
          this.getActivatedCategory(this.categories);
        }
        this.productsService.categoriesWithProductsResponse = this.categories;
      })
    }
  }

  /**
   * Selected a category according to url params
   * @param categories products category
   */
  async getActivatedCategory(categories) {

    let shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
    let bodega
    if (shippingMethod === null || shippingMethod == undefined) {
      bodega = ''
    } else if (shippingMethod.type === 'Domicilio') {
      bodega = (shippingMethod.address) ? shippingMethod.address.inventario : ''
    }
    else {
      bodega = shippingMethod.salePoint.bodega;
    }

    let products = this.allProducts

    var doubleFilteredProducts = []
    if (bodega === '') {
      doubleFilteredProducts = products
    } else {
      products.forEach(function (element) {
        if (element['inventarioBodega'][bodega] !== 0) {
          doubleFilteredProducts.push(element)
        }
      })
    }
    doubleFilteredProducts = _.orderBy(doubleFilteredProducts, ['index'], ['asc'])
    this.activatedRoute.paramMap.subscribe(async (params) => {
      let shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
      document.getElementById('div-scroll').scrollTo(0, 0);
      if (params.has('category')) {
        this.allCategories = {
          nombre: 'Todos los productos',
          productos: this.filteredProducts(doubleFilteredProducts),
        };
        const categoryParam = params.get('category');
        

        if (categoryParam !== 'todos' && categoryParam !== 'busqueda') {
          const categorySelected = categories.filter((category) => category.slug === categoryParam)[0];
          
          this.categorySelected = {
            nombre: categorySelected.nombre,
            productos: categorySelected.productos,
          };
          this.setBrands(categorySelected.productos);
          this.productsShow = this.categorySelected.productos;
          if (this.user.uid) {
            this.productsShow = this.filteredProducts(this.productsShow)
          }
          this.productsService.onSendCategoryParam(
            this.categorySelected.nombre
          );
          return true;
        }

        if (categoryParam === 'todos') {
          this.categorySelected = {
            nombre: 'Todos los productos',
            productos: this.productsService.filterProductsByWarehouse(this.allProducts, shippingMethod),
            slug: 'todos',
          };
          this.setBrands(this.categorySelected.productos);
          this.productsShow = this.categorySelected.productos;
          if (this.user.uid) {
            this.productsShow = this.filteredProducts(this.productsShow)
          }
          this.productsService.onSendCategoryParam(
            this.categorySelected.nombre
          );
          this.productsShow = this.productsService.filterProductsByWarehouse(
            this.categorySelected.productos,
            shippingMethod
          );
          this.productsService.onSendCategoryParam(this.categorySelected.nombre);
          return true;
        }
        if (categoryParam === 'busqueda') {
          this.categorySelected = {
            nombre: 'Búsqueda',
            /**
             * apagando productos de distribuidor temporalmente.
             * TODO: quitar esta mientes se se tiene la funcionalidad de apagar
             * Esta es una solucion temporal .filter(element => !element.distribuidor),
             */
            productos: this.allProducts
          };
          this.setBrands(this.categorySelected.productos);
          this.productsShow = this.categorySelected.productos;
          if (this.user.uid) {
            this.productsShow = this.filteredProducts(this.productsShow)
          }
          this.productsService.onSendCategoryParam(
            this.categorySelected.nombre
          );          
          // Activate the search  when entering from the search
          if (isPlatformBrowser(this.platFormId)) {
            const element = document.getElementById('searchProduct');            
            element.focus();
          }
          return true;
        }
      }
    });
  }

  /**
   * Get information from user, products and address.
   */
  initializeUserAndProductInfo(): void {
    if (isPlatformBrowser(this.platFormId)) {
      const userLogged = JSON.parse(this.localStorage.getItem('userLogged'));
      if (userLogged) {
        this.userService.getUserIdLogged().subscribe((userId) => {
          if (userId) {
            this.getUserInfo(userId);
          }
        });
      } else {
        const shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
        this.getCategoriesWithProductsAPI(shippingMethod, false);
        this.getShippingMethodActivated(shippingMethod);
      }
    }
  }

  /**
   * Update the global shippingMethod
   * @param shippingMethod El metodo de envio
   */
  getShippingMethodActivated(shippingMethod: ShippingMethod): void {
    if (shippingMethod?.type === ShippingMethodType.SHIPPING) {
      if (shippingMethod.address) {
        this.userService.onSendGlobalShippingMethod(shippingMethod);
      } else {
        if (isPlatformBrowser(this.platFormId)) {
          shippingMethod = JSON.parse(this.localStorage.getItem('shippingMethod'));
          this.userService.onSendGlobalShippingMethod(shippingMethod);
        }
      }
    } else if (shippingMethod?.type === ShippingMethodType.IN_STORE) {
      this.userService.onSendGlobalShippingMethod(shippingMethod);
    }
  }

  setBrands(productos: Product[]): void {
    this.brandNames = [];
    productos.forEach((p) => {
      if (p.marca && !this.brandNames.includes(p.marca)) {
        this.brandNames.push(p.marca);
      }
    });
  }

  filterByBrand(brand): void {
    this.selectedBrand = brand;
    if (brand) {
      this.productsShow = this.categorySelected.productos.filter(p => p.marca === brand.nombre);
      if (this.user.uid) {
        this.productsShow = this.filteredProducts(this.productsShow)
      }
    } else {
      this.productsShow = this.categorySelected.productos;
      if (this.user.uid) {
        this.productsShow = this.filteredProducts(this.productsShow)
      }
    }
  }
}
